import React from 'react'

import { Row, Three } from '../components/Grid'
import SEO from '../components/SEO'
import { SEOH1 } from '../components/Style'
import ContactForm from '../components/ContactForm'
import Layout from '../components/Layout'

const breadcrumbs = `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": "https://500tech.com/contact",
      "name": "Contact Us"
    }
  }]
}`

export default props => {
  return (
    <Layout>
      <SEO
        path="/contact"
        title="Contact 500Tech"
        description="Contact 500Tech, a leading development consulting firm and front-end agency working with React, Angular, and advanced technologies."
        ld={breadcrumbs}
      />

      <SEOH1>Contact us</SEOH1>

      <Row>
        <Three>
          <p>
            We provide development, consulting, and training services. Our
            expertise is React and Angular. We'd love to help you
            with a new or existing project.
          </p>
        </Three>
      </Row>

      <ContactForm />
    </Layout>
  )
}
